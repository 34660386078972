<div
	class="flex h-[100dvh] overflow-hidden"
	[ngClass]="behaviourStates.sidebarOpen ? 'sidebar-expanded' : ''"
>
	<div>
		<div
			class="fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200"
			[ngClass]="behaviourStates.sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'"
			(click)="toggleSidebar()"
		></div>
		<div
			class="flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-[100dvh] overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out"
			[ngClass]="behaviourStates.sidebarOpen ? 'translate-x-0' : '-translate-x-64'"
		>
			<div class="flex justify-between mb-10 pr-3 sm:px-2">
				<button
					class="lg:hidden text-slate-500 hover:text-slate-400"
					(click)="toggleSidebar()"
				>
					<span class="sr-only">Close sidebar</span>
					<svg
						class="w-6 h-6 fill-current"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
					</svg>
				</button>
				<a
					class="block"
					[routerLink]="['./dashboard']"
				>
					<img
						class="object-contain object-center h-12 sidebar-expanded:hidden"
						src="/assets/images/brand/isotipo.png"
						alt="midespacho_logo"
					/>
					<img
						class="object-contain object-center h-12 hidden sidebar-expanded:block"
						src="/assets/images/brand/app-logo-white.png"
						alt="midespacho_logo"
					/>
				</a>
			</div>
			<div class="space-y-8">
				<div>
					<ul class="mt-3 select-none">
						<!-- Investigación -->
						<li
							class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
							routerLinkActive="sidebar-element-expanded bg-slate-900"
							cdkAccordion
						>
							<cdk-accordion-item #accordionItemInvestigation="cdkAccordionItem">
								<a
									class="block text-slate-200 truncate transition duration-150 cursor-pointer"
									(click)="accordionItemInvestigation.toggle(); behaviourStates.sidebarOpen = true"
								>
									<div class="flex items-center justify-between">
										<div class="flex items-center">
											<div class="w-6 h-6 grid place-content-center">
												<i
													class="fa-duotone fa-file-magnifying-glass"
													style="font-size: 1.5rem; --fa-primary-color: #817df2; --fa-secondary-color: #a5b4fc; --fa-primary-opacity: 1; --fa-secondary-opacity: 1"
												></i>
											</div>
											<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"> Investigación </span>
										</div>
										<div class="flex shrink-0 ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
											<svg
												class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
												viewBox="0 0 12 12"
												[ngClass]="accordionItemInvestigation.expanded ? 'rotate-180' : 'rotate-0'"
											>
												<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
											</svg>
										</div>
									</div>
								</a>
								<div class="lg:!hidden lg:sidebar-expanded:!block sidebar-element-expanded:block">
									<ul
										class="pl-9 mt-1"
										[ngClass]="accordionItemInvestigation.expanded ? 'block' : 'hidden'"
									>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./search/engine']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"> Búsqueda Inteligente </span>
											</a>
										</li>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./search/collections']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"> Colecciones </span>
											</a>
										</li>
									</ul>
								</div>
							</cdk-accordion-item>
						</li>

						<!-- Calendario -->
						<li
							class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
							routerLinkActive="sidebar-element-expanded bg-slate-900"
							cdkAccordion
						>
							<cdk-accordion-item #accordionItemCalendar="cdkAccordionItem">
								<a
									class="block text-slate-200 truncate transition duration-150 cursor-pointer"
									(click)="accordionItemCalendar.toggle(); behaviourStates.sidebarOpen = true"
								>
									<div class="flex items-center justify-between">
										<div class="flex items-center">
											<div
												class="w-6 h-6 grid place-content-center"
												[routerLink]="['./calendar']"
											>
												<i
													class="fa-duotone fa-calendars iconCalendar"
													routerLinkActive="iconCalendarActive"
													style="font-size: 1.5rem"
												></i>
											</div>
											<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"> Calendario </span>
										</div>
										<div class="flex shrink-0 ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
											<svg
												class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
												viewBox="0 0 12 12"
												[ngClass]="accordionItemCalendar.expanded ? 'rotate-180' : 'rotate-0'"
											>
												<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
											</svg>
										</div>
									</div>
								</a>
								<div class="lg:!hidden lg:sidebar-expanded:!block sidebar-element-expanded:block">
									<ul
										class="pl-9 mt-1"
										[ngClass]="accordionItemCalendar.expanded ? 'block' : 'hidden'"
									>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./calendar/home']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"> Resumen </span>
											</a>
										</li>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./calendar/collections']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"> Colecciones </span>
											</a>
										</li>
									</ul>
								</div>
							</cdk-accordion-item>
						</li>
					</ul>

					<h3 class="mt-3 text-xs uppercase text-slate-500 font-semibold pl-3">
						<span class="hidden lg:block lg:sidebar-expanded:hidden text-center w-6">•••</span>
						<span class="lg:hidden lg:sidebar-expanded:block">Administración</span>
					</h3>

					<ul class="mt-3 select-none">
						<!-- Dashboard -->
						<li
							class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
							routerLinkActive="sidebar-element-expanded bg-slate-900"
							cdkAccordion
						>
							<cdk-accordion-item #accordionItemDashborad="cdkAccordionItem">
								<a
									class="block text-slate-200 truncate transition duration-150 cursor-pointer"
									(click)="accordionItemDashborad.toggle(); behaviourStates.sidebarOpen = true"
								>
									<div class="flex items-center justify-between">
										<div class="flex items-center">
											<svg
												class="shrink-0 h-6 w-6 pointer-events-none"
												viewBox="0 0 24 24"
												[routerLink]="['./dashboard']"
											>
												<path
													class="fill-current text-slate-400"
													routerLinkActive="!text-indigo-500"
													d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
												/>
												<path
													class="fill-current text-slate-600"
													routerLinkActive="!text-indigo-600"
													d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
												/>
												<path
													class="fill-current text-slate-400"
													routerLinkActive="!text-indigo-200"
													d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
												/>
											</svg>
											<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Inicio</span>
										</div>
										<div class="flex shrink-0 ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
											<svg
												class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
												viewBox="0 0 12 12"
												[ngClass]="accordionItemDashborad.expanded ? 'rotate-180' : 'rotate-0'"
											>
												<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
											</svg>
										</div>
									</div>
								</a>
								<div class="lg:!hidden lg:sidebar-expanded:!block sidebar-element-expanded:block">
									<ul
										class="pl-9 mt-1"
										[ngClass]="accordionItemDashborad.expanded ? 'block' : 'hidden'"
									>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./dashboard']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Resumen</span>
											</a>
										</li>
									</ul>
								</div>
							</cdk-accordion-item>
						</li>

						<!-- Experiencias -->
						<li
							class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
							routerLinkActive="sidebar-element-expanded bg-slate-900"
							cdkAccordion
						>
							<cdk-accordion-item #accordionItemExperience="cdkAccordionItem">
								<a
									class="block text-slate-200 truncate transition duration-150 cursor-pointer"
									(click)="accordionItemExperience.toggle(); behaviourStates.sidebarOpen = true"
								>
									<div class="flex items-center justify-between">
										<div class="flex items-center">
											<svg
												class="shrink-0 h-6 w-6"
												viewBox="0 0 24 24"
												[routerLink]="['./experiences']"
											>
												<path
													class="fill-current text-slate-600"
													routerLinkActive="!text-indigo-500"
													d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z"
												></path>
												<path
													class="fill-current text-slate-400"
													routerLinkActive="!text-indigo-300"
													d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z"
												></path>
											</svg>
											<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Experiencias</span>
										</div>
										<div class="flex shrink-0 ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
											<svg
												class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
												viewBox="0 0 12 12"
												[ngClass]="accordionItemExperience.expanded ? 'rotate-180' : 'rotate-0'"
											>
												<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
											</svg>
										</div>
									</div>
								</a>
								<div class="lg:!hidden lg:sidebar-expanded:!block sidebar-element-expanded:block">
									<ul
										class="pl-9 mt-1"
										[ngClass]="accordionItemExperience.expanded ? 'block' : 'hidden'"
									>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./experiences/create']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Crear</span>
											</a>
										</li>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./experiences/list']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Listar</span>
											</a>
										</li>
									</ul>
								</div>
							</cdk-accordion-item>
						</li>

						<!-- Venues -->
						<li
							class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
							routerLinkActive="sidebar-element-expanded bg-slate-900"
							cdkAccordion
						>
							<cdk-accordion-item #accordionItemVenue="cdkAccordionItem">
								<a
									class="block text-slate-200 truncate transition duration-150 cursor-pointer"
									(click)="accordionItemVenue.toggle(); behaviourStates.sidebarOpen = true"
								>
									<div class="flex items-center justify-between">
										<div class="flex items-center">
											<svg
												class="shrink-0 h-6 w-6"
												viewBox="0 0 24 24"
												[routerLink]="['./venues']"
											>
												<path
													class="fill-current text-slate-400"
													routerLinkActive="!text-indigo-300"
													d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
												></path>
												<path
													class="fill-current text-slate-700"
													routerLinkActive="!text-indigo-600"
													d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
												></path>
												<path
													class="fill-current text-slate-600"
													routerLinkActive="!text-indigo-500"
													d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
												></path>
											</svg>
											<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Venues</span>
										</div>
										<div class="flex shrink-0 ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
											<svg
												class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
												viewBox="0 0 12 12"
												[ngClass]="accordionItemVenue.expanded ? 'rotate-180' : 'rotate-0'"
											>
												<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
											</svg>
										</div>
									</div>
								</a>
								<div class="lg:!hidden lg:sidebar-expanded:!block sidebar-element-expanded:block">
									<ul
										class="pl-9 mt-1"
										[ngClass]="accordionItemVenue.expanded ? 'block' : 'hidden'"
									>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./venues/create']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Crear</span>
											</a>
										</li>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./venues/list']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Listar</span>
											</a>
										</li>
									</ul>
								</div>
							</cdk-accordion-item>
						</li>

						<!-- Usuarios -->
						<li
							class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
							routerLinkActive="sidebar-element-expanded bg-slate-900"
							cdkAccordion
						>
							<cdk-accordion-item #accordionItemUsers="cdkAccordionItem">
								<a
									class="block text-slate-200 truncate transition duration-150 cursor-pointer"
									(click)="accordionItemUsers.toggle(); behaviourStates.sidebarOpen = true"
								>
									<div class="flex items-center justify-between">
										<div class="flex items-center">
											<svg
												class="shrink-0 h-6 w-6 pointer-events-none"
												viewBox="0 0 24 24"
												[routerLink]="['./users']"
											>
												<path
													class="fill-current text-slate-600"
													routerLinkActive="!text-indigo-500"
													d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
												/>
												<path
													class="fill-current text-slate-400"
													routerLinkActive="!text-indigo-300"
													d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
												/>
											</svg>
											<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Usuarios</span>
										</div>
										<div class="flex shrink-0 ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
											<svg
												class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
												viewBox="0 0 12 12"
												[ngClass]="accordionItemUsers.expanded ? 'rotate-180' : 'rotate-0'"
											>
												<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
											</svg>
										</div>
									</div>
								</a>
								<div class="lg:!hidden lg:sidebar-expanded:!block sidebar-element-expanded:block">
									<ul
										class="pl-9 mt-1"
										[ngClass]="accordionItemUsers.expanded ? 'block' : 'hidden'"
									>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./users/create']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Crear</span>
											</a>
										</li>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./users/list']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Listar</span>
											</a>
										</li>
									</ul>
								</div>
							</cdk-accordion-item>
						</li>

						<!-- Administración -->
						<li
							class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
							routerLinkActive="sidebar-element-expanded bg-slate-900"
							cdkAccordion
						>
							<cdk-accordion-item #accordionItemAdmin="cdkAccordionItem">
								<a
									class="block text-slate-200 truncate transition duration-150 cursor-pointer"
									(click)="accordionItemAdmin.toggle(); behaviourStates.sidebarOpen = true"
								>
									<div class="flex items-center justify-between">
										<div class="flex items-center">
											<svg
												class="shrink-0 h-6 w-6 pointer-events-none"
												viewBox="0 0 24 24"
												[routerLink]="['./admin']"
											>
												<circle
													class="fill-current text-slate-400"
													routerLinkActive="!text-indigo-300"
													cx="18.5"
													cy="5.5"
													r="4.5"
												></circle>
												<circle
													class="fill-current text-slate-600"
													routerLinkActive="!text-indigo-500"
													cx="5.5"
													cy="5.5"
													r="4.5"
												></circle>
												<circle
													class="fill-current text-slate-600"
													routerLinkActive="!text-indigo-500"
													cx="18.5"
													cy="18.5"
													r="4.5"
												></circle>
												<circle
													class="fill-current text-slate-400"
													routerLinkActive="!text-indigo-300"
													cx="5.5"
													cy="18.5"
													r="4.5"
												></circle>
											</svg>
											<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Administración</span>
										</div>
										<div class="flex shrink-0 ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
											<svg
												class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
												viewBox="0 0 12 12"
												[ngClass]="accordionItemAdmin.expanded ? 'rotate-180' : 'rotate-0'"
											>
												<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
											</svg>
										</div>
									</div>
								</a>
								<div class="lg:!hidden lg:sidebar-expanded:!block sidebar-element-expanded:block">
									<ul
										class="pl-9 mt-1"
										[ngClass]="accordionItemAdmin.expanded ? 'block' : 'hidden'"
									>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./admin/banks_accounts']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Bancos</span>
											</a>
										</li>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./admin/payments_confirm']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Confirmar Pagos</span>
											</a>
										</li>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./admin/exchange_rates']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Tasa de Cambio</span>
											</a>
										</li>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./admin/tickets_types']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Tipos de Boletos</span>
											</a>
										</li>
									</ul>
								</div>
							</cdk-accordion-item>
						</li>

						<!-- Configuracion -->
						<li
							class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
							routerLinkActive="sidebar-element-expanded bg-slate-900"
							cdkAccordion
						>
							<cdk-accordion-item #accordionItemSettings="cdkAccordionItem">
								<a
									class="block text-slate-200 truncate transition duration-150 cursor-pointer"
									(click)="accordionItemSettings.toggle(); behaviourStates.sidebarOpen = true"
								>
									<div class="flex items-center justify-between">
										<div class="flex items-center">
											<svg
												class="shrink-0 h-6 w-6 pointer-events-none"
												viewBox="0 0 24 24"
												[routerLink]="['./settings']"
											>
												<path
													class="fill-current text-slate-600"
													routerLinkActive="!text-indigo-500"
													d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
												></path>
												<path
													class="fill-current text-slate-400"
													routerLinkActive="!text-indigo-300"
													d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
												></path>
												<path
													class="fill-current text-slate-600"
													routerLinkActive="!text-indigo-500"
													d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
												></path>
												<path
													class="fill-current text-slate-400"
													routerLinkActive="!text-indigo-300"
													d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
												></path>
											</svg>
											<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Configuración</span>
										</div>
										<div class="flex shrink-0 ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
											<svg
												class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
												viewBox="0 0 12 12"
												[ngClass]="accordionItemSettings.expanded ? 'rotate-180' : 'rotate-0'"
											>
												<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
											</svg>
										</div>
									</div>
								</a>
								<div class="lg:!hidden lg:sidebar-expanded:!block sidebar-element-expanded:block">
									<ul
										class="pl-9 mt-1"
										[ngClass]="accordionItemSettings.expanded ? 'block' : 'hidden'"
									>
										<li class="mb-1 last:mb-0">
											<a
												class="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"
												[routerLink]="['./settings/profiles']"
												routerLinkActive="!text-indigo-500"
											>
												<span class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Perfiles y Permisos</span>
											</a>
										</li>
									</ul>
								</div>
							</cdk-accordion-item>
						</li>
					</ul>
				</div>
			</div>
			<div class="pt-3 hidden lg:inline-flex justify-end mt-auto">
				<div class="px-3 py-2">
					<button (click)="toggleSidebar()">
						<span class="sr-only">Expand / collapse sidebar</span>
						<svg
							class="w-6 h-6 fill-current sidebar-expanded:rotate-180"
							viewBox="0 0 24 24"
						>
							<path
								class="text-slate-400"
								d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
							/>
							<path
								class="text-slate-600"
								d="M3 23H1V1h2z"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="relative flex flex-col flex-1 overflow-y-hidden overflow-x-hidden">
		<header class="sticky top-0 bg-white border-b border-slate-200 z-30">
			<div class="px-4 sm:px-6 lg:px-8">
				<div class="flex items-center justify-between h-16 -mb-px">
					<div class="flex">
						<button
							class="text-slate-500 hover:text-slate-600 lg:hidden"
							(click)="toggleSidebar()"
						>
							<span class="sr-only">Open sidebar</span>
							<svg
								class="w-6 h-6 fill-current"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									x="4"
									y="5"
									width="16"
									height="2"
								/>
								<rect
									x="4"
									y="11"
									width="16"
									height="2"
								/>
								<rect
									x="4"
									y="17"
									width="16"
									height="2"
								/>
							</svg>
						</button>
					</div>

					<!-- Header: Right side -->
					<div class="flex items-center space-x-3">
						<!-- Search button -->
						<div>
							<!-- Button -->
							<button class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full">
								<span class="sr-only">Search</span>
								<svg
									class="w-4 h-4"
									viewBox="0 0 16 16"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										class="fill-current text-slate-500"
										d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
									></path>
									<path
										class="fill-current text-slate-400"
										d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
									></path>
								</svg>
							</button>
						</div>

						<!-- Notifications button -->
						<div class="relative inline-flex">
							<button class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full">
								<span class="sr-only">Notifications</span>
								<svg
									class="w-4 h-4"
									viewBox="0 0 16 16"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										class="fill-current text-slate-500"
										d="M6.5 0C2.91 0 0 2.462 0 5.5c0 1.075.37 2.074 1 2.922V12l2.699-1.542A7.454 7.454 0 006.5 11c3.59 0 6.5-2.462 6.5-5.5S10.09 0 6.5 0z"
									></path>
									<path
										class="fill-current text-slate-400"
										d="M16 9.5c0-.987-.429-1.897-1.147-2.639C14.124 10.348 10.66 13 6.5 13c-.103 0-.202-.018-.305-.021C7.231 13.617 8.556 14 10 14c.449 0 .886-.04 1.307-.11L15 16v-4h-.012C15.627 11.285 16 10.425 16 9.5z"
									></path>
								</svg>
								<div class="absolute top-0 right-0 w-2.5 h-2.5 bg-rose-500 border-2 border-white rounded-full"></div>
							</button>
						</div>

						<!-- Info button -->
						<div class="relative inline-flex">
							<button class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full">
								<span class="sr-only">Info</span>
								<svg
									class="w-4 h-4"
									viewBox="0 0 16 16"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										class="fill-current text-slate-500"
										d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
									></path>
								</svg>
							</button>
						</div>

						<!-- Divider -->
						<hr class="w-px h-6 bg-slate-200" />

						<!-- User button -->
						<div
							id="user-menu"
							class="relative inline-flex"
						>
							<button
								(click)="toggleUserMenu()"
								class="inline-flex justify-center items-center group"
							>
								<img
									class="w-8 h-8 rounded-full"
									[src]="sessionUserState.google_user_photo ? sessionUserState.google_user_photo : '/assets/images/general/profile-placeholder.png'"
									src="/assets/images/brand/goliiive_logo_blue_iso.svg"
									width="32"
									height="32"
									alt="User"
								/>
								<div class="flex items-center truncate">
									<span class="truncate ml-2 text-sm font-medium group-hover:text-slate-800">{{ sessionUserState.user_name }} {{ sessionUserState.user_last_name }}</span>
									<svg
										class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
										viewBox="0 0 12 12"
									>
										<path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"></path>
									</svg>
								</div>
							</button>

							<div
								[@fadeInOutY]="userMenuOpen ? 'in' : 'out'"
								[ngClass]="userMenuOpen ? 'pointer-events-auto' : 'pointer-events-none'"
								class="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
							>
								<div class="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
									<div class="font-medium text-slate-800">{{ sessionUserState.user_name }} {{ sessionUserState.user_last_name }}</div>
									<div class="text-xs text-slate-500 italic">{{ sessionUserState.email }}</div>
								</div>
								<ul>
									<li>
										<button
											(click)="clearSession()"
											class="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
										>
											Cerrar Sesión
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main
			class="max-h-[calc(100vh-4rem)] overflow-y-auto overflow-x-hidden flex-1"
			cdk-scrollable
		>
			<router-outlet></router-outlet>
		</main>
	</div>
</div>
