import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, delay, filter, finalize, shareReplay, switchMap, take, tap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/features/auth/services/auth.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		// Si es una solicitud de refrescamiento de token se ignora
		if (request.headers.has('Skip-Refresh-Interceptor')) {
			request = request.clone({
				headers: request.headers.delete('Skip-Refresh-Interceptor')
			});

			return next.handle(request);
		}

		// Si el token es invalido se hace el refresh
		if (!this.authService.validAccessToken) {
			if (!this.authService.validRefreshToken) {
				// Logout
			}

			// Esta parte esta mejorada con chatgpt o1-preview
			// Permite recibir varias solicitudes cuando el token
			// se esta refrescando incluso si operadores como switchMap
			// cancelan las emisiones originales, creo que este es el
			// mecanismo de refrescamiento definitivo
			if (!this.isRefreshing) {
                
				this.isRefreshing = true;
				this.refreshTokenSubject.next(null);

				// Comenzamos el proceso de refresco de token y compartimos el observable
				const refreshToken$ = this.authService.refreshToken().pipe(
					tap((newToken) => {
						this.isRefreshing = false;
						this.refreshTokenSubject.next(newToken);
					}),
					catchError((error) => {
						this.isRefreshing = false;
						// Manejar el error de refresco, posiblemente hacer logout
						return throwError(() => error);
					}),
					finalize(() => {
						this.isRefreshing = false;
					}),
					shareReplay(1) // Compartimos el observable para que múltiples suscriptores reciban la misma emisión
				);

				return refreshToken$.pipe(
					switchMap(() => {
						// Reintentamos la solicitud original con el nuevo token
						return next.handle(this.addToken(request));
					})
				);
			} else {
                
				// Si ya se está refrescando, esperamos a que el token sea refrescado
				return this.refreshTokenSubject.pipe(
					filter((token) => token != null),
					take(1),
					switchMap(() => {
						// Reintentamos la solicitud original con el nuevo token
						return next.handle(this.addToken(request));
					})
				);
			}
		}

		// Se ejecuta el request con normalidad
		return next.handle(request);
	}

	private addToken(request: HttpRequest<any>): HttpRequest<any> {
		return request.clone({ setHeaders: { 'X-Access-Token': this.authService.getAccessToken } });
	}
}
